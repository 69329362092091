<!--
 * @Author: snltty
 * @Date: 2021-10-11 14:17:09
 * @LastEditors: snltty
 * @LastEditTime: 2021-10-12 09:46:35
 * @version: v1.0.0
 * @Descripttion: 功能说明
 * @FilePath: \pc\src\views\Course.vue
-->
<template>
    <div class="course">
        <el-carousel :interval="5000" arrow="always" class="carousel">
            <el-carousel-item>
                <div class="img" style="background-image:url(./images/sk.jpg)"></div>
                <div class="text">
                    <h3>第一个环节</h3>
                    <p>听老师讲解故事内容，比如我们的试听课的故事内容是《三顾茅庐》，小朋友们先听老师讲解内容；</p>
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="img" style="background-image:url(./images/by.jpg)"></div>
                <div class="text">
                    <h3>第二个环节</h3>
                    <p>角色扮演，老师将组织小朋友扮演诸葛亮、刘备、关羽、张飞，沉浸式演绎故事内容、理解故事内容。</p>
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="img" style="background-image:url(./images/pjm.jpg)"></div>
                <div class="text">
                    <h3>第三个环节</h3>
                    <p>拼装益智积木，拼装的物体造型，也是故事内容相关的物体，例如《三顾茅庐》试听课程是拼装诸葛亮的房子造型，老师讲解完房子构造，再引导小朋友们完成任务。</p>
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="img" style="background-image:url(./images/zdu.jpg)"></div>
                <div class="text">
                    <h3>第四个环节</h3>
                    <p>编程，根据故事内容设置的迷宫地图，小朋友通过拼接编程代码块，完成编程任务。通过编程任务的完成来认识数字、方向的使用，循序渐进了解编程概念，训练编程思维、为之后的进阶课程做准备。</p>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    setup () {
        return {}
    }
}
</script>

<style lang="stylus">
@media screen and (max-width: 768px)
    .course
        .carousel
            .el-carousel__container
                height: calc(100vh - 61px) !important;

.course
    .carousel
        .el-carousel__container
            height: calc(100vh - 132px);

    .img
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

    .text
        position: absolute;
        left: 2rem;
        bottom: 20%;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 1rem;
        max-width: 80%;

        h3
            font-size: 2rem;

        p
            font-size: 1.6rem;
            margin-top: 1rem;
</style>